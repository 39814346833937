/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
	// All pages
	'common': {
	  init: function() {
		// JavaScript to be fired on all pages
		
/*==============================================================================

  # Local scroll 
  # Menu-btn
  # Banner fixed class
  # Product sidemenu
  # Product inovice

==============================================================================*/

/*==============================================================================
  # Local scroll 
==============================================================================*/

function scrollTo( ele, time ){
	var headerHeight = $( '#banner' ).outerHeight(),
		extraOffset = 30,
		elePos = ele.offset().top,
		offset = elePos-headerHeight-extraOffset;

	$("html, body").animate({
		scrollTop: offset+'px' 
	}, time );
}
var hash = window.location.hash;
$( 'a' ).on( 'click', function( e ){
	var target = $( this );
	//Do nothing if the link is a part of a no-scroll element
	if( !(target.parents( '.no-scroll' ).length) ){
		//Get the a-tag if target is a child to the a-tag
		if( !(target.is( 'a' )) ){
			target = target.closest( 'a' );
		}
		//Check for hash, prevent default if found	
		var href = target.attr( 'href' ),
			hrefIndex = href.indexOf('#');
		//If link contains hash
		if( hrefIndex >= 0 ){
			var hrefLength = href.length,
				hash = href.substring( hrefIndex, hrefLength );
			//If hash exists on this page
			if( $( hash ).length ){
				e.preventDefault();
				scrollTo( $( hash ), 500 );
			}
		}
	}
});
//Scrolls to hash on page load if it exists
if( window.location.hash.length > 0 ){
	if( $( hash ).length ){
		scrollTo( $( hash ), 1500 );
	}
}


/*==============================================================================
  # Menu-btn
==============================================================================*/

var menuBtn = $( '.menu-btn' ),
	fullScreenNav = $( '.full-screen-nav' );

menuBtn.on('click',function(e){
	$(this).toggleClass( 'open' );
	fullScreenNav.toggleClass( 'open' );
});


/*==============================================================================
  # Banner fixed class
==============================================================================*/

var banner = $( '.banner' );

function headerFixed( scrollVal ){
	if( scrollVal > 0 ){
		banner.addClass( 'scrolled' );
	}else{
		banner.removeClass( 'scrolled' );		
	}
}

/*==============================================================================
  # Product sidemenu
==============================================================================*/

$('#sidemenu').on('click',function(e){
	if( $(e.target).hasClass('sidemenu') || $(e.target).hasClass('sidemenu-title') ){
		$( this ).toggleClass( 'open' );
		if( $( this ).hasClass( 'open' ) ){
			scrollTo( $('#sidemenu'), 500 );
		}
	}
});

$( window ).on('click', function(e){
	if( !$( e.target ).closest( '.sidemenu' ).length ){
		$('#sidemenu').removeClass( 'open' );
	}
});

$( '.menu-item a' ).on('click',function(e){

	if( $( e.target ).closest( 'li' ).hasClass( 'has-children' ) ){
		e.preventDefault();
		var subMenu = $( e.target ).closest( 'a' ).next(),
			hasClass = subMenu.hasClass('open');
		$( '.sub-menu.open' ).hide( 500 ).removeClass('open');
		if( !hasClass ){
			subMenu.show( 500 ).addClass( 'open' );
		}
	}else{
		$('#sidemenu').removeClass( 'open' );
	}
});


/*==============================================================================
  # Product inovice
==============================================================================*/

	

	var inoviceBox = $( '#inovice-box' ),
		dataProductid = inoviceBox.attr( 'data-productid' );
	inoviceBox.find( 'input[name="productid"]' ).attr( 'value', dataProductid );




$( window ).resize(function(e){
	headerFixed( $(window).scrollTop() );
});
$( window ).scroll(function(){
	headerFixed( $(window).scrollTop() );
});
headerFixed( $(window).scrollTop() );

/*==============================================================================
  <!-- End of custom javascript -->  
==============================================================================*/

	  },
	  finalize: function() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	  }
	},
	// Home page
	'home': {
	  init: function() {
		// JavaScript to be fired on the home page
	  },
	  finalize: function() {
		// JavaScript to be fired on the home page, after the init JS
	  }
	},
	// About us page, note the change from about-us to about_us.
	'about_us': {
	  init: function() {
		// JavaScript to be fired on the about us page
	  }
	}
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
	fire: function(func, funcname, args) {
	  var fire;
	  var namespace = Sage;
	  funcname = (funcname === undefined) ? 'init' : funcname;
	  fire = func !== '';
	  fire = fire && namespace[func];
	  fire = fire && typeof namespace[func][funcname] === 'function';

	  if (fire) {
		namespace[func][funcname](args);
	  }
	},
	loadEvents: function() {
	  // Fire common init JS
	  UTIL.fire('common');

	  // Fire page-specific init JS, and then finalize JS
	  $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
		UTIL.fire(classnm);
		UTIL.fire(classnm, 'finalize');
	  });

	  // Fire common finalize JS
	  UTIL.fire('common', 'finalize');
	}
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
